const IMAGES = {
  b1: require("../img/home/b1.png"),
  b2: require("../img/home/b2.png"),
  b3: require("../img/home/b3.png"),
  b4: require("../img/home/b4.png"),
  b5: require("../img/home/b5.png"),
  pgu: require("../img/products/pgu.jpg"),
};

export default IMAGES;
