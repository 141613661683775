import React from "react";
import Layout from "../components/Layout/Layout";
import "./PhoneSkins.css";
import IMAGES from "../img/image";
import Games from "../components/Games";

const PhoneSkins = () => {
  return (
    <Layout>
      <div className="phone-skins-container">
        <img src={IMAGES.b5} alt="" />
      </div>
      <div className="phone-skin-product-container">
        <Games title={"Popular Games"} />
      </div>
      {/* <HowItWorks /> */}
    </Layout>
  );
};

export default PhoneSkins;
