import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { message } from "antd";
import "./AdminQueries.css";

const AdminQueries = () => {
  const [queries, setQueries] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [view, setView] = useState(0);
  const [singleQuery, setSingleQuery] = useState(null);
  const [msg, setMsg] = useState(null);

  const getAllQueries = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-queries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setQueries(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSeen = async (id) => {
    try {
      const res = await axios.post(
        "/api/admin/query-seen",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getAllQueries();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSubtmit(id) {
    try {
      const res = await axios.post("/api/contact/update-query", {
        id: id,
        msg: msg,
        person: "admin",
      });
      if (res.data.success) {
        message.success(res.data.message);
        getAllQueries();
        setView(0);
        setMsg("");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllQueries();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Queries</h3>
        <h6>Total Queries - {queries?.length}</h6>
      </div>
      <hr />
      {view === 0 && (
        <div className="admin-queries">
          <div className="admin-offer-btn-tabs">
            <button
              onClick={() => setTabs(0)}
              className={`${tabs === 0 && "active"}`}
            >
              Pending
            </button>
            <button
              onClick={() => setTabs(1)}
              className={`${tabs === 1 && "active"}`}
            >
              Closed
            </button>
          </div>
          <table className="table query-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            {loading ? (
              <div className="loader-container text-center">
                <span class="loader"></span>
              </div>
            ) : tabs === 0 ? (
              <tbody>
                {queries &&
                  queries
                    ?.filter((item) => {
                      return item.status === "pending";
                    })
                    .map((item, index) => {
                      return (
                        <tr>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.mobile}</td>
                          <td>
                            {item?.msg[0]?.msg}
                            {/* <button
                              onClick={() => {
                                setView(1);
                                setSingleQuery(item);
                              }}
                              className="add-to-cart-btn w-100"
                            >
                              View
                            </button> */}
                          </td>
                          <td>
                            <button
                              onClick={() => handleSeen(item?._id)}
                              className="add-to-cart-btn w-100"
                            >
                              Close
                            </button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <tbody>
                {queries &&
                  queries
                    ?.filter((item) => {
                      return item.status === "seen";
                    })
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.msg[0]?.msg}</td>
                          <td>close</td>
                        </tr>
                      );
                    })}
              </tbody>
            )}
          </table>
        </div>
      )}

      {view === 1 && (
        <>
          <div className="back-btnn" onClick={() => setView(0)}>
            <ArrowBackIcon className="icon" />
            Back
          </div>
          <div className="admin-query-reply query-reply-container">
            {singleQuery?.msg?.map((item, index) => {
              return (
                <div
                  className={`query-msg text-dark ${
                    item?.person === "user" ? "user" : "admin"
                  }`}
                >
                  {item?.msg}
                </div>
              );
            })}
          </div>
          <textarea
            onChange={(e) => setMsg(e.target.value)}
            className="my-3 form-control"
            name="msg"
            rows="4"
          ></textarea>
          <button
            onClick={() => handleSubtmit(singleQuery?._id)}
            className="register-btn mt-3"
          >
            Submit
          </button>
        </>
      )}
    </AdminLayout>
  );
};

export default AdminQueries;
