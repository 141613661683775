import React from "react";
import Slider from "react-slick";
import IMAGES from "../../img/image";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import "./Testimonials.css";

const Testimonials = () => {
  const agents = [
    {
      name: "Jeeteshori Kh",
      desc: "I purchased Mobile Legends diamonds from this store and am highly satisfied with their prompt, affordable, and reliable services. Thanks!",
    },
    {
      name: "Joyraj M",
      desc: "Just made a purchase of Mobile Legends diamonds from this shop. Speedy, inexpensive, and reliable service. Thank you for the excellent experience!",
    },
    {
      name: "David Kh",
      desc: "I highly recommend you guys to get topup from this store. Excellent and fast service. Go and get your work done. Hurry up!",
    },
    {
      name: "Nitish Y",
      desc: "Bought Mobile Legends diamonds here, and I'm extremely pleased with the service provided. Fast, reliable, and hassle-free. Highly recommended!",
    },
    {
      name: "Luxmi Kh",
      desc: "Purchased Mobile Legends diamonds and couldn't be happier with the experience. Quick delivery and excellent customer support.",
    },
    {
      name: "Jelly",
      desc: "I highly recommend this store for Mobile Legends top-ups. Fast, efficient, and great value for money. Don't hesitate, give them a try!",
    },
    {
      name: "Dravid Kh",
      desc: "Excellent service for Mobile Legends top-ups. Quick, reliable, and hassle-free. Will definitely be returning for more. Highly satisfied!",
    },
    {
      name: "Thoibi",
      desc: "Just made a purchase of Mobile Legends diamonds and the service was exceptional. Fast delivery and great communication. Highly recommend!",
    },
  ];

  var settings = {
    dots: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="testimonials-container">
      <span className="text-center d-block m-auto">
        <span className="text-green">
          <small>
            <b>Reviews</b>
          </small>
        </span>
        <h4>What Customer Says About Us</h4>
      </span>
      <div className="testimonial-slider">
        <Slider {...settings}>
          {agents?.map((agent, index) => {
            return (
              <div key={index} className="testimonial">
                <div className="testimonial-content">
                  <h6>{agent.name}</h6>
                  <span>
                    <small>{agent.desc}</small>
                  </span>
                  <FormatQuoteIcon className="icon" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
