import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminQueries.css";
import axios from "axios";
import { message } from "antd";

const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/payment/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Payments</h3>
      </div>
      <hr />
      <div className="admin-queries">
        <table className="table query-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Client Txn ID</th>
              <th>Date</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader-container text-center">
              <span class="loader"></span>
            </div>
          ) : (
            <tbody>
              {payments &&
                payments?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <small>{item?.name}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>Rs. {item?.price}</small>
                      </td>
                      <td>
                        <small>{item?.amount}</small>
                      </td>
                      <td>
                        <small>{item?.client_txn_id}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          })}
                        </small>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
